// extracted by mini-css-extract-plugin
export var copyright = "styles-module--copyright--kxwRj";
export var darkAccent = "#D44344";
export var darkShades = "#1F2733";
export var delta = "undefined";
export var footer = "styles-module--footer--czYej";
export var lightAccent = "#C5A68D";
export var lightShades = "#F5F6F3";
export var mainBrandColor = "#CE871D";
export var nav = "styles-module--nav--D3P+t";
export var topRow = "styles-module--topRow--5sXYL";