// extracted by mini-css-extract-plugin
export var currentCategory = "styles-module--currentCategory--LfOdd";
export var darkAccent = "#D44344";
export var darkShades = "#1F2733";
export var delta = "undefined";
export var header = "styles-module--header--UVbH6";
export var headerText = "styles-module--headerText--+uQ-f";
export var innerWrapper = "styles-module--inner-wrapper--LIaL7";
export var lightShades = "#F5F6F3";
export var logo = "styles-module--logo--0-1Qi";
export var mainBrandColor = "#CE871D";
export var navLink = "styles-module--navLink--agrDz";